import React, { Component } from "react"
import Layout from "../components/Layout/layout"

class IndexPage extends Component {
  state = {
    smallScreen: false,
    modal: { name: "" },
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this))
    this.resize()
  }

  resize() {
    this.setState({ smallScreen: window.innerWidth <= 975 })
  }

  openModal(e) {
    this.setState({ modal: e })
    document.getElementById("modal").style.display = "block"
  }

  closeModal() {
    document.getElementById("modal").style.display = "none"
  }
  render() {
    let indexData = this.props.data.allIndexJson.nodes[0]
    let homeItems = []
    let i = 0
    indexData.home_items.forEach(item => {
      homeItems.push(
        <div
          key={i}
          onClick={this.openModal.bind(this, item)}
          className={
            this.state.smallScreen ? "grid-item-small" : "home-grid-item"
          }
          style={{
            backgroundImage: `url(${item.image})`,
            backgroundSize: "100%",
          }}
        ></div>
      )
      i++
    })
    return (
      <Layout page={"home"}>
        <div className="home-main">
          <h1 className="title">In-Home Boarding for<br /> Stress Free Stays</h1> 
          <div className="text">We provide the perfect in-home pet care experience to give you peace of mind while you're away.  We specialize in reducing stress for you and your dogs. Located in Milan, Michigan we are set up with all the comforts of home. Our one-acre, fenced backyard is perfect for running and playing.</div>
          <div className="text">With over ten years of experience, you can be sure the family you leave with us will receive lots of TLC and be begging to return.  We keep in touch by text or email throughout the stay, so you know your pets are receiving the best care.</div>
          <div className="text">We offer affordable rates and multi-dog discounts to our clients, including pickup and dropoff at your home.  Flexible scheduling ensures we're there when you need us.</div>
          <div
            className={
              this.state.smallScreen
                ? "grid-container-small"
                : "home-grid-container"
            }
          >
            {/* {homeItems}  commented to remove testimonial grid until we are ready*/}
          </div>
        </div>
        <div id="modal" className="modal" onClick={this.closeModal}>
          <div
            className={
              this.state.smallScreen ? "modal-content-small" : "modal-content"
            }
          >
            <span className="modal-close">&times;</span>
            <div className="modal-grid-container">
              <div className="modal-grid-item-left">
                <span className="modal-title">{this.state.modal.name}</span>
                <p className="modal-text">{this.state.modal.description}</p>
                <p className="modal-text">
                  Completed: {this.state.modal.completed}
                </p>
              </div>
              <div className="modal-grid-item-right">
                <img
                  src={this.state.modal.image}
                  alt={this.state.modal.name}
                  className="modal-image"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query {
    allIndexJson {
      nodes {
        title
        description
        text
        text2
        subtitle
        home_items {
          name
          description
          completed
          image
        }
      }
    }
  }
`
